
import { Options, Vue } from "vue-class-component";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { IFeedback } from "@/types/Feedback";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

@Options({
  name: "Feedback",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        subject: yup
          .string()
          .required("Message subject is required!")
          .max(100, "Must be maximum 100 characters!"),
        message: yup
          .string()
          .required("Message is required!")
          .max(200, "Must be maximum 200 characters!"),
      }),
    };
  },
})
export default class Feedback extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  crumbs: any = ["Dashboard", "Feedback"];
  feedback: IFeedback = {
    subject: "",
    message: "",
  };
  message = "";
  loading = false;

  async save() {
    this.loading = true;
    this.workForceService
      .postOrPut<void>("/feedback", this.feedback as IFeedback, undefined)
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          (this.$refs["confirmDialogue"] as any)
            .show({
              title: "Success",
              message: "Feedback Sent Successfully",
              okButton: "Ok",
              theme: "success",
              type: "notification",
            })
            .then(() => {
              if (response) {
                this.message = "";
                this.feedback.subject = "";
                this.feedback.message = "";
                var form = this.$refs["form"] as any;
                form.resetForm();
              }
            });
        } else if (response.isSuccess == false) {
          this.loading = false;
          this.message = response.errors;
        } else {
          this.loading = false;
          const errors = response.errors.errors;
          for (const key in errors) {
            this.message += key + ": " + errors[key][0] + "<br>";
          }
        }
      })
      .catch(() => (this.loading = false));
  }
}
